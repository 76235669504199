import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import './Accessories.css'

class Ev extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.EnableEv()
    }

    render() {
        var options = []
        for (var i = 0; i < this.props.EvOptions.length; i++)
            options.push(<option value={i}>{this.props.EvOptions[i]}</option>)

        if (!this.props.Mobile)
            return (
                <div className={"row accessory-row ev-row "}>
                    <div style={{ maxHeight: '30px' }}>
                        <InputGroup.Checkbox style={{ cursor: 'pointer' }} checked={this.props.EvEnabled} onChange={this.handleClick} />
                    </div>
                    <p className="inverter-words">{this.props.AddEvChargerWord}</p>

                    <select value={this.props.EvChoice} disabled={!this.props.EvEnabled} className="inverter-select" onChange={this.props.EvChange}> {options}</select>
                </div>
            )

        else
            return (
                <div className={"row accessory-row ev-row ev-row-mobile inverter-mobile-row"}>
                    <div className="row accessory-row inverter-mobile-row">
                        <div style={{ maxHeight: '30px' }}>
                            <InputGroup.Checkbox style={{ cursor: 'pointer' }} checked={this.props.EvEnabled} onChange={this.handleClick} />
                        </div>
                        <p className="inverter-words inverter-mobile-heading">{this.props.AddEvChargerWord}</p>
                    </div>
                    <select value={this.props.EvChoice} disabled={!this.props.EvEnabled} className="inverter-select ev-select-mobile" onChange={this.props.EvChange}> {options}</select>
                </div>
            )
    }
}


export default Ev;
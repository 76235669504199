import React from 'react';
import './Footer.css';
import './Fonts.css';

class Footer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        var footerLinks = [];
        for (const link of this.props.links) {
            if (link.indexOf("www.") != -1) {
                footerLinks.push(<a className={"FooterLink " + (this.props.mobile ? " FooterLinkMobile" : "")} href={(link.indexOf("http") == -1 ? "http://" : "") + link} target="_blank" rel="noopener noreferrer">{link}</a>);
            }
            else {
                footerLinks.push(<a className={"FooterLink " + (this.props.mobile ? "FooterLinkMobile" : "")}>{link}</a>);
            }

            if (!this.props.mobile) {
                footerLinks.push(<div className="FooterSeparator"></div>)
            }
        }

        if (!this.props.mobile)
            footerLinks.pop();

        return (<div className="FooterContainer">
            
            <div className={"FooterLinks TitleFont " + (this.props.mobile ? "FooterLinksMobile" : "")}>
                {footerLinks}
            </div>
            <p className="VersionNumber Segoe">V{this.props.versionNumber}</p>
            
        </div>);
    }
}


export default Footer;
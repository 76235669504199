import React from 'react';
import Inverters from './Inverters.js'
import Ev from './Ev.js'
import './Accessories.css'

class Accessories extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        var inverters = [];
        if (this.props.ShowInverters) {
            inverters = <Inverters Mobile={this.props.Mobile} EnableInverters={this.props.EnableInverters} AddInverter={this.props.AddInverter} DeleteInverter={this.props.DeleteInverter} InvertersEnabled={this.props.InvertersEnabled} AddInverterWords={this.props.AddInverterWords} InverterChange={this.props.InverterChange} InverterTypes={this.props.InverterTypes} DefaultInverterOption={this.props.DefaultInverterOption} StringWord={this.props.StringWord} AddStringWord={this.props.AddStringWord} InvertersChosen={this.props.InvertersChosen} />;
        }

        var ev = [];
        if (this.props.ShowEv) {
            ev = <Ev Mobile={this.props.Mobile} AddEvChargerWord={this.props.AddEvChargerWord} EvChange={this.props.EvChange} EnableEv={this.props.EnableEv} EvEnabled={this.props.EvEnabled} EvChoice={this.props.EvChoice} EvOptions={this.props.EvOptions} />;
        }

        if (!(this.props.ShowEv || this.props.ShowInverters)) {
            return (<div></div>)
        }

        return (
            <div className={"accessories-container " + (this.props.Mobile ? "accessories-container-mobile" : "")}>
                <p className="accessories-title">{this.props.AccessoriesWord}</p>
                {inverters}
                {ev}
            </div>)

    }
}


export default Accessories;
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import Inverter from './Inverter.js'
import './Accessories.css'

class Inverters extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.EnableInverters()
    }

    render() {
        var inverters = [];

        for (var i = 0; i < this.props.InvertersChosen.length; i++) {
            var currentInverter = <Inverter Mobile={this.props.Mobile} DeleteInverter={this.props.DeleteInverter} InverterNum={i} InverterChange={this.props.InverterChange} InverterTypes={this.props.InverterTypes} DefaultInverterOption={this.props.DefaultInverterOption} StringWord={this.props.StringWord} SelectedOption={this.props.InvertersChosen[i]} Disabled={!this.props.InvertersEnabled} TotalInverters={this.props.InvertersChosen.length} />;

            if (this.props.Mobile)
                currentInverter = <div className="row accessory-row inverter-mobile-row">{currentInverter}</div>

            inverters.push(currentInverter);
        }

        if (!this.props.Mobile)
            return (
                <div className="row accessory-row">
                    <div style={{ maxHeight: '30px' }}>
                        <InputGroup.Checkbox style={{ cursor: 'pointer' }} checked={this.props.InvertersEnabled} onChange={this.handleClick} />
                    </div>
                    <p className="inverter-words">{this.props.AddInverterWords}</p>

                    <div className="row accessory-row inverter-row">
                        {inverters}
                        <div className="row add-row">
                            <a className={(this.props.InvertersEnabled ? "add-cross" : "disabled-add")} onClick={this.props.AddInverter} />
                            <p className={"inverter-add" + (this.props.InvertersEnabled ? "" : " disabled-accessory")}>{this.props.AddStringWord}</p>
                        </div>
                    </div>
                </div>
            )
        else {
            return (
                <div className="row accessory-row inverter-mobile-row">
                    <div className="row accessory-row inverter-mobile-row">
                        <div style={{ maxHeight: '30px' }}>
                            <InputGroup.Checkbox style={{ cursor: 'pointer' }} checked={this.props.InvertersEnabled} onChange={this.handleClick} />
                        </div>
                        <p className="inverter-words inverter-mobile-heading">{this.props.AddInverterWords}</p>
                    </div>

                    <div className="row accessory-row inverter-mobile-row">
                        {inverters}
                        <div className="row add-row add-row-mobile">
                            <a className={(this.props.InvertersEnabled ? "inverter-add-mobile" : "disabled-add-mobile")} onClick={this.props.AddInverter} />
                            <p className={"inverter-add" + (this.props.InvertersEnabled ? "" : " disabled-accessory")}>{this.props.AddStringWord}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }
}


export default Inverters;
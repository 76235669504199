import React from 'react';
import './Accessories.css'

class Inverter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.deleteClick = this.deleteClick.bind(this)
        this.inverterChange = this.inverterChange.bind(this)
    }

    deleteClick() {
        this.props.DeleteInverter(this.props.InverterNum);
    }

    inverterChange(e) {
        this.props.InverterChange(this.props.InverterNum, e.target.value);
    }

    render() {
        var options = [<option value={-1}>{this.props.DefaultInverterOption}</option>];
        for (var i = 0; i < this.props.InverterTypes.length; i++)
            options.push(<option value={i}>{this.props.InverterTypes[i]}</option>)

        var cross = []
        if (this.props.TotalInverters > 1) {
            cross = <a className={(this.props.Disabled ? "disabled-delete" : "delete-cross")} onClick={this.deleteClick} style={{ cursor: 'pointer' }} />;
        }

        return(
            <div className="inverter-container">
                <div className="row inverter-top">
                    <p className={"inverter-string " + (this.props.Disabled ? " disabled-accessory" : "")}>{this.props.StringWord} {this.props.InverterNum + 1}</p>
                    {cross}
                </div>

                <select value={this.props.SelectedOption} disabled={this.props.Disabled} className={"inverter-select " + (this.props.Mobile ? " inverter-select-mobile" : "")} onChange={this.inverterChange}>  {options}</select>
            </div>
        );

    }
}


export default Inverter;
import PanelType from '.././Enums/PanelType.js'

var hasVbr = false;

function getOffset(panelType, portrait)
{
    var m10PortraitVbr = 0;
    if (hasVbr) {
        m10PortraitVbr = 2;
    }

    switch (panelType) {
        case PanelType.DEFAULT:
            return 0;
        case PanelType.G1:
            return portrait ? 13 : 11;
        case PanelType.M10:
            return portrait ? 26 + m10PortraitVbr : 22;
    }
}

function setHasVbr(val) {
    hasVbr = val;
}

export { getOffset, setHasVbr };
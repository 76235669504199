import React from 'react';
import './DropDown.css'
import './Fonts.css'

//Handles the ArcBox drop down menu
class ArcBoxDropDown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 0
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        this.props.press(e.target.value)
        this.setState({
            status: e.target.value
        })

    }

    infoHover(e)
    {
        document.getElementById("ArcLink").classList.add("ArcBlue");
    }

    infoLeave(e) {
        document.getElementById("ArcLink").classList.remove("ArcBlue");
    }

    render() {
        this.state.status = this.props.dropDownValue;
        var fontSize = "80%";
        var options = []
        var optionWords = [this.props.firstOption, this.props.secondOption, this.props.thirdOption];
        for (var i = 0; i < optionWords.length; i++) {
            options.push(<option style={{ direction: "rtl" }} value={i}>{optionWords[i]}</option>)
            if (optionWords[i].length > "site made connectors only".length)
                fontSize = "60%"
        }    

        if (this.props.mobile)
            return (<div className="DropDown2" style={{ marginTop: "5%" }}><div style={{ display: "flex", flexShrink: "0", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><a target="_blank" rel="noopener noreferrer" href={this.props.arcBoxLink} className="DropDown" style={{ fontFamily: "arial", fontSize: "60%", marginTop: "-0px" }}>{this.props.arcBoxWord} &nbsp; &nbsp;</a><a className="InformationMobile" target="_blank" rel="noopener noreferrer" href={this.props.arcBoxLink}></a><select style={{ width: "167px", height: "30px", fontFamily: "arial", fontSize: "54%", textAlignLast: "right" }} value={this.state.status}
                onChange={this.handleChange} >
                {options}
            </select></div></div>)
        else
            return (<div className="DropDown2" style={{ marginTop: "5%" }}><div style={{ display: "flex", flexShrink: "0", flexDirection: "row" }}><a target="_blank" rel="noopener noreferrer" id="ArcLink" href={this.props.arcBoxLink} className="DropDown DropdownHover" style={{ fontFamily: "arial", fontSize: "80%" }}>{this.props.arcBoxWord}&nbsp;  </a><a target="_blank" rel="noopener noreferrer" href={this.props.arcBoxLink} className="Information DropdownHover" id="Info" onMouseOver={this.infoHover} onMouseLeave={this.infoLeave}></a><select style={{ width: "235px", fontFamily: "arial", fontSize: "80%", textAlignLast: "right" }} value={this.state.status}
                onChange={this.handleChange} >
                {options}
            </select></div></div>)
    }
}


export default ArcBoxDropDown;